<script>
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { LOG_TYPE, logger } from '@emobg/web-utils';
import {
  fetchRequiredDocuments,
  hasCompletedBadge,
  hasUploadedDrivingLicense,
  isRequiredDocumentsLoading,
  requiredDocuments,
} from '@/stores/UserValidations/UserValidationsMapper';
import {
  fetchUserDetails,
  userData,
} from '@/stores/User/UserData/UserDataMapper';
import { useDrivingLicenseRoadblock } from '@/composable/User/documents/drivingLicense';
import { useKeycardRoadblock } from '@/composable/User/documents/keycard';
import { fetchUserAccount } from '@/stores/User/UserMapper';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { useTheme } from '@/composable/Theme/useTheme';
import RequiredDocumentsComponent from './components/RequiredDocuments/RequiredDocumentsComponent';

export default {
  name: 'CustomerValidationComponent',

  components: {
    RequiredDocumentsComponent,
  },

  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedLocation: {
      type: Object,
      default: null,
    },
    onValidationCompleted: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    const { showRoadblock: showDrivingLicense } = useDrivingLicenseRoadblock();
    const { showRoadblock: showKeycard } = useKeycardRoadblock();
    const { notifyError } = useNotifications();
    return {
      fetchButtonSpecs,
      showDrivingLicense,
      showKeycard,
      notifyError,
    };
  },

  data() {
    return {
      isLoading: false,
      isDisabled: false,
    };
  },

  computed: {
    requiredDocuments,
    isRequiredDocumentsLoading,
    hasUploadedDrivingLicense,
    hasCompletedBadge,
    userData,

    requiredDocumentsWithTextAndCaption() {
      return map(this.requiredDocuments, requiredDocument => ({
        ...requiredDocument,
        key: requiredDocument.type,
        text: this.$t(`BookingView.CustomerValidationComponent.${requiredDocument.type}_text`),
        caption: this.$t(`BookingView.CustomerValidationComponent.${requiredDocument.type}_caption`),
      }));
    },
  },

  async created() {
    if (isEmpty(this.requiredDocuments)) {
      fetchRequiredDocuments({
        csOperatorUuid: get(this.selectedLocation, 'cs_operator_uuid'),
      });
    }
  },

  methods: {
    fetchUserAccount,
    onCancel() {
      this.$emit('update:is-open', false);
    },

    async onClickContinue() {
      const validations = [
        {
          status: !this.hasUploadedDrivingLicense,
          method: () => this.showDrivingLicenseRoadblock(),
        },
        {
          status: !this.hasCompletedBadge,
          method: () => this.showKeycardRoadblock(),
        },
      ];

      const validation = find(validations, 'status');

      if (validation) {
        validation.method();
      }

      this.$emit('update:is-open', false);
    },

    async showDrivingLicenseRoadblock() {
      this.showDrivingLicense({
        hasCompletedBadge: this.hasCompletedBadge,
        onDrivingLicenseCreated: () => {
          this.fetchUserData();
        },
        onAfterClose: () => {
          if (!this.hasCompletedBadge) {
            this.showKeycardRoadblock();
          }
        },
        onError: () => {
          this.showErrorNotification();
        },
      });
    },

    showKeycardRoadblock() {
      this.showKeycard({
        onKeycardRequested: () => {
          this.onValidationCompleted();
        },
        onKeycardLinked: () => {
          this.onValidationCompleted();
        },
        onKeycardPickedUp: () => {
          this.onValidationCompleted();
        },
        onAfterClose: () => {
          this.fetchUserData();
        },
        onError: () => {
          this.showErrorNotification();
        },
      });
    },

    fetchUserData() {
      return Promise.all([
        fetchUserDetails(),
        this.fetchUserAccount({
          userUuid: get(this, 'userData.uuid'),
        }),
      ]).catch(() => {
        logger.message('Error refreshing data', LOG_TYPE.error);
      });
    },

    showErrorNotification() {
      this.$fullScreen.close();
      this.notifyError({
        text: this.$t('notifications.whooops'),
        textAction: this.$t('buttons.ok'),
      });
    },
  },
};
</script>

<template>
  <ui-modal
    :open="isOpen"
    :header="$t('BookingView.CustomerValidationComponent.title')"
    :size="SIZES.medium"
    data-test-id="validation_status-modal"
    @input="value => $emit('input', value)"
  >
    <div slot="body">
      <p
        class="emobg-font-default emobg-font-line-height-default"
        v-html="$t('BookingView.CustomerValidationComponent.subtitle')"
      />
      <RequiredDocumentsComponent
        :documents="requiredDocumentsWithTextAndCaption"
        :is-loading="isRequiredDocumentsLoading"
        class="mt-4"
      />
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap align-items-center justify-content-end"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isRequiredDocumentsLoading"
        class="mr-3"
        data-test-id="cancel-button"
        @clickbutton="onCancel"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isRequiredDocumentsLoading"
        data-test-id="continue-button"
        @clickbutton="onClickContinue"
      >
        {{ $t('buttons.continue') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
